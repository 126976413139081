import React, {useState} from "react";
import {Subscription} from "../../models/Subscription";
import {Button, Grid, TextField} from '@mui/material';
import {analytics} from "../../firebase";
import {logEvent} from "firebase/analytics";
import * as amplitude from '@amplitude/analytics-browser';
import logo from "../../assets/images/logoCoyali.svg"
import snowman from "../../assets/images/bonhommeneige.png"
import "../../sass/components/CodeInput.scss"

interface Prop {
  nextStep: () => void;
  subscription: Subscription;
  setSubscription: React.Dispatch<React.SetStateAction<Subscription>>;
}

function CodeInput({nextStep, subscription, setSubscription}: Prop) {

  const [codeError, setCodeError] = useState(false);

  const handleSubmit = () => {

    if (subscription.code !== "WX8ZHC") {
      setCodeError(true);
      logEvent(analytics, 'lp_code_ko');
      amplitude.track("lp_code", {
        valid: true,
        code: subscription.code
      });
      return;
    }

    logEvent(analytics, 'lp_code_ok');
    amplitude.track("lp_code", {
      valid: false,
      code: subscription.code
    });
    nextStep();
  };

  function codeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const tempSubscription: Subscription = {...subscription};
    tempSubscription.code = value.toUpperCase();
    setSubscription(tempSubscription);
  }


  return (
      <div className="codeInput">
        <div className="logo-container">
          <img src={logo} alt="coyali, l'application qui simplifie votre smartphone"/>
        </div>
        <Grid container
              alignItems="center"
              spacing={3} className="user-infos_form">
          <Grid item xs={12} md={6}>

            <h3 className="subtitle">Entrez votre code<br/> cadeau de Noël !</h3>

            <TextField
                label="Code"
                id="code"
                name="code"
                required={true}
                value={subscription.code}
                onChange={codeChange}
                variant="outlined"
                error={codeError}
                helperText={codeError ? "Code incorrect." : null}
                fullWidth/>
          </Grid>


          <Grid item xs={12} container justifyContent="center">
            <Button
                className="button-primary"
                variant="contained"
                onClick={handleSubmit}>
              Valider
            </Button>
          </Grid>
        </Grid>
        <div className="illu-container">
          <img src={snowman} alt="Offre de noël coyali"/>
        </div>
      </div>
  );
}

export default CodeInput;
