import React, {useState} from "react";
import "../sass/pages/Subscribe.scss";
import GetUserInfos from "../components/Subscribe/GetUserInfo";
import {Subscription} from "../models/Subscription";
import Validation from "../components/Subscribe/Validation";
import CodeInput from "../components/Subscribe/CodeInput";

export const SubscribePath = "/"

// TODO renommer en NewSubscription
function Subscribe() {
  const [step, setStep] = useState(1);

  const [subscription, setSubscription] = useState<Subscription>(
      {
        customer: {
          firstName: "",
          lastName: "",
          phone: "+33",
          email: "",
          birthdate: ""
        },
        timestamp: Date.now().toString(),
        code: "",
        password: "",
      });

  function nextStep() {
    setStep(step + 1);
    console.log(subscription)
  }

  console.log(subscription)
  return (
      <main className="subscribe">
        <div className="subscribe-content">

          {step === 1 ? <CodeInput
              nextStep={nextStep}
              subscription={subscription}
              setSubscription={setSubscription}/> : null}

          {step === 2 ? <GetUserInfos
              nextStep={nextStep}
              subscription={subscription}
              setSubscription={setSubscription}/> : null}

          {step === 3 ? <Validation/> : null}
        </div>
      </main>
  );
}

export default Subscribe;