import React from "react";
import logo from "../../assets/images/Logo.svg";
import store from "../../assets/images/store-img.png";
import arrow from "../../assets/images/arrow-orange.svg";
import footerImg from "../../assets/images/bg-coyali-bottom.png"
import "../../sass/components/Validation.scss"

function Validation () {

    return (
        <div className="validation">
           <div className="validation-content">
            <div className="logo">
                <img src={logo} alt="Coyali - Le smartphone simplifié" />
            </div>
             <h1>Félicitations,<br/>votre compte à bien<br/>été crée !</h1>
            <div className="store">
                <a href="https://play.google.com/store/apps/details?id=com.coyali.app&hl=fr&gl=US" target="_blank" rel="noreferrer">
                    <img src={store} alt="Télécharger Coyali sur le playstore" />
                </a>
                <h2>
                    Vous pouvez maintenant<br/>télécharger votre<br/>application en cliquant ici.
                </h2>
                <img src={arrow} className="arrow" alt="Télécharger coyali" />
            </div>
            <div className="footer">
                <img src={footerImg}  alt="Offrez coyali pour Noël" />
            </div>
           </div>
        </div>
    )
}

export default Validation