import {Subscription} from "../models/Subscription";
import {getFunctions, httpsCallable} from "firebase/functions";


export function createUserCF(subscription: Subscription): Promise<void> {
  return new Promise((resolve, reject) => {
    const createUser = httpsCallable(getFunctions(), 'V2_createUserFromOfferLP');

    createUser(subscription).then((result) => {
      resolve();
    }).catch((error) => {
      reject(error);
    });
  });
}