import React from "react";
import {Route, Routes} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import "./sass/utils/reset.scss"
import 'react-toastify/dist/ReactToastify.css';
import "./sass/App.scss"
import Subscribe, {SubscribePath} from "./pages/Subscribe";
import './sass/App.scss';
import NotFound from "./pages/NotFound";
import * as amplitude from '@amplitude/analytics-browser';


function App() {

  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY as string, {serverZone: "EU"});
  
  // const identifyEvent = new amplitude.Identify();
  // amplitude.identify(identifyEvent);

  return (

      <div className="App">
        <Routes>
          <Route path={SubscribePath} element={(<Subscribe/>)}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
        <ToastContainer/>
      </div>
  );
}

export default App;