import React, {ChangeEvent, useState} from "react";
import {Subscription} from "../../models/Subscription";
import {Button, Grid, IconButton, InputAdornment, TextField} from '@mui/material';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {createUserCF} from "../../cloudFunctions/createUserAndStripePendingIntentCF";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../firebase";
import "../../sass/components/GetUserInfos.scss";
import forme from "../../assets/images/forme.png";
import logo from "../../assets/images/Logo.svg"
import mockup from "../../assets/images/mockup.png"
import humans from "../../assets/images/humans.png"
import facebook from "../../assets/images/facebook.svg"
import insta from "../../assets/images/instagram.svg"
import linkdin from "../../assets/images/linkdin.svg"

interface Prop {
  nextStep: () => void;
  subscription: Subscription;
  setSubscription: React.Dispatch<React.SetStateAction<Subscription>>;
}

const validNameRegex = /^[A-Za-z\s\-]+$/; // eslint-disable-line
const validFrenchPhoneRegex = /^\+33\d{9}$/; // Format +33 suivi de 9 chiffres
const validEmailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/;
const birthdateRegex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/; // eslint-disable-line


function GetUserInfos({nextStep, subscription, setSubscription}: Prop) {


  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false)
  const [apiError, setApiError] = useState(undefined)
  const [birthdateError, setBirthdateError] = useState(false)
  const [password1Error, setPassword1Error] = useState(false)
  const [password2Error, setPassword2Error] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    logEvent(analytics, 'lp_form_submit');
    if (!subscription || !subscription.customer) {
      return;
    }
    let valid = true;

    console.log("birthdate : ", subscription.customer.birthdate);

    if (!validNameRegex.test(subscription.customer.lastName) || subscription.customer.lastName === "") {
      setLastNameError(true);
      valid = false;
    } else {
      setLastNameError(false);
    }

    if (!validNameRegex.test(subscription.customer.firstName) || subscription.customer.firstName === "") {
      setFirstNameError(true);
      valid = false;
    } else {
      setFirstNameError(false);
    }

    if (!birthdateRegex.test(subscription.customer.birthdate) || subscription.customer.birthdate === "") {
      setBirthdateError(true);
      valid = false;
    } else {
      setBirthdateError(false);
    }

    if (!validEmailRegex.test(subscription.customer.email) || subscription.customer.email === "") {
      setEmailError(true);
      valid = false;
    } else {
      setEmailError(false);
    }

    if (!validFrenchPhoneRegex.test(subscription.customer.phone) || subscription.customer.phone === "") {
      setMobileError(true);
      valid = false;
    } else {
      setMobileError(false);
    }

    if (!passwordRegex.test(subscription.password) || subscription.password === "") {
      setPassword1Error(true);
      valid = false;
    } else {
      setPassword1Error(false);
    }

    if (passwordConfirmation !== subscription.password) {
      setPassword2Error(true);
      valid = false;
    } else {
      setPassword2Error(false);
    }


    if (valid) {
      setLoading(true)

      createUserCF(subscription)
          .then(() => {
            nextStep()
            logEvent(analytics, 'lp_user_created');
          })
          .catch((error) => {
            setLoading(false)
            setApiError(error.details.message)
            logEvent(analytics, 'lp_user_creation_error');
          });
    } else {
      setErrorMessage(true);
      logEvent(analytics, 'lp_form_invalid');
    }
  };

  //--------------------------------------//
  // FONCTION DE GESTION DES INPUT CHANGE //
  function firstNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFirstNameError(false)
    const value = e.target.value;
    const tempSubscription: Subscription = {...subscription};
    tempSubscription.customer.firstName = value.charAt(0).toUpperCase() + value.slice(1);
    setSubscription(tempSubscription);
  }

  function lastNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setLastNameError(false)
    const value = e.target.value;
    const tempSubscription: Subscription = {...subscription};
    tempSubscription.customer.lastName = value.toUpperCase();
    setSubscription(tempSubscription);
  }

  function mobileChange(e: React.ChangeEvent<HTMLInputElement>) {
    setMobileError(false)
    const tempSubscription: Subscription = {...subscription}
    tempSubscription.customer.phone = e.currentTarget.value
    setSubscription(tempSubscription)
  }

  function birthdateChange(e: ChangeEvent<HTMLInputElement>) {
    setBirthdateError(false)
    const tempSubscription: Subscription = {...subscription}
    tempSubscription.customer.birthdate = e.currentTarget.value;
    setSubscription(tempSubscription);
  }

  const passwordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword1Error(false)
    const tempSubscription: Subscription = {...subscription}
    tempSubscription.password = e.currentTarget.value;
    setSubscription(tempSubscription);
  };

  const passwordConfirmationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword2Error(false)
    setPasswordConfirmation(e.currentTarget.value);
  };

  const emailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailError(false)
    const tempSubscription: Subscription = {...subscription}
    tempSubscription.customer.email = e.currentTarget.value;
    setSubscription(tempSubscription);
  };


  return (
      <div className="getUserInfos">
        <div className="getUserInfos-content">
          <img className="forme" src={forme} alt="offre de Noël Coyali - le smartphone simplifié"/>
          <img className="mockup" src={mockup} alt="Coyali compagnon - l'application pour aider vos proches"/>
          <div className="heading">
            <div className="heading-title">
              <img className="logo" src={logo} alt="Coyali - le smartphone simplifié"/>
              <h1>
                Vos proches<br/> ont pensé à<br/> vous pour<br/>Noël !
              </h1><br/>
              <img className="humans" src={humans} alt=""/>
              <p className="subtitle">
                Créez maintenant votre compte et<br/>accédez à l’application
                Coyali,<br/><span>gratuitement et à vie !</span>
              </p>
            </div>
          </div>
          <Grid container spacing={3} className="user-infos_form" justifyContent="center">
            <h2>Créer votre compte Coyali <span className="underline"></span></h2>
            <Grid item xs={12}>
              <TextField
                  label="Votre nom"
                  id="lastName"
                  name="lastName"
                  required={true}
                  value={subscription.customer.lastName}
                  onChange={lastNameChange}
                  variant="outlined"
                  error={lastNameError}
                  helperText={lastNameError ? "Le nom doit uniquement contenir des lettres, des espaces et des tirets." : ""}
                  fullWidth/>
            </Grid>

            <Grid item xs={12}>
              <TextField
                  label="Votre prénom"
                  id="firstName"
                  name="firstName"
                  required={true}
                  value={subscription.customer.firstName}
                  onChange={firstNameChange}
                  variant="outlined"
                  error={firstNameError}
                  helperText={firstNameError ? "Le prénom doit uniquement contenir des lettres, des espaces et des tirets." : ""}
                  fullWidth/>
            </Grid>

            <Grid item xs={12}>
              <TextField
                  label="Votre date de naissance"
                  type="date"
                  required={true}
                  value={subscription.customer.birthdate}
                  onChange={birthdateChange}
                  InputLabelProps={{shrink: true}}
                  error={birthdateError}
                  helperText={birthdateError ? "Le format attendu est de la forme : JJ/MM/AAAA" : ""}
                  fullWidth/>
            </Grid>

            <Grid item xs={12}>
              <TextField
                  label="Votre email"
                  required={true}
                  id="email"
                  name="email"
                  value={subscription.customer.email}
                  onChange={emailChange}
                  variant="outlined"
                  error={emailError}
                  helperText={emailError ? "Veuillez entrer un email valide." : ""}
                  fullWidth/>
            </Grid>

            <Grid item xs={12}>
              <TextField
                  label="Votre numéro de téléphone mobile"
                  id="mobile"
                  name="mobile"
                  required={true}
                  value={subscription.customer.phone}
                  onChange={mobileChange}
                  variant="outlined"
                  error={mobileError}
                  helperText={mobileError ? "Le format attendu est +33 suivi de 9 chiffres." : ""}
                  fullWidth/>
            </Grid>

            <Grid item xs={12}>
              <TextField
                  label="Définissez votre mot de passe"
                  id="password1"
                  name="password1"
                  required={true}
                  value={subscription.password}
                  onChange={passwordChange}
                  variant="outlined"
                  type={showPassword1 ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowPassword1(!showPassword1)
                              }}
                              edge="end"
                          >
                            {showPassword1 ? <Visibility/> : <VisibilityOff/>}
                          </IconButton>
                        </InputAdornment>
                    )
                  }}
                  error={password1Error}
                  helperText={password1Error ? "Le mot de passe doit contenir 8 caractères, et au minimum 1 lettre et 1 chiffre." : ""}
                  fullWidth/>
            </Grid>

            <Grid item xs={12}>
              <TextField
                  label="Confirmez votre mot de passe"
                  id="password2"
                  name="password2"
                  required={true}
                  value={passwordConfirmation}
                  onChange={passwordConfirmationChange}
                  variant="outlined"
                  type={showPassword2 ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowPassword2(!showPassword2)
                              }}
                              edge="end"
                          >
                            {showPassword2 ? <Visibility/> : <VisibilityOff/>}
                          </IconButton>
                        </InputAdornment>
                    )
                  }}
                  error={password2Error}
                  helperText={password2Error ? "La confirmation de mot de passe ne correspond pas." : ""}
                  fullWidth/>
            </Grid>


            {errorMessage && (
                <Grid item xs={12}>
                  <div className="error-message font-medium">Veuillez remplir tous les champs obligatoires</div>
                </Grid>
            )}

            {apiError !== undefined && (
                <Grid item xs={12}>
                  <div className="error-message font-medium">{apiError}</div>
                </Grid>
            )}

            <Grid item xs={12} container justifyContent="center">
              <Button
                  className="button-primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={loading}>
                Créer mon compte Coyali
              </Button>
            </Grid>
          </Grid>
        </div>
        <footer>
          <div className="footer-heading">
            <img src={logo} alt="Coyali - le smartphone simplifié"/>
          </div>
          <div className="footer-content">
            <div className="footer-nav">
              <a href="https://coyali.notion.site/Centre-d-aide-b86b370317d14e4992cbfdbedd8e6451" target="_blank" rel="noreferrer">FAQ</a><br/>
              <a href="https://coyali.com/mentions-legales" target="_blank" rel="noreferrer">Mentions légales</a><br/>
              <a href="https://coyali.com/politique-de-confidentialite" target="_blank" rel="noreferrer">Politique de confidentialité</a>
            </div>
            <div className="socials">
              <h3>Retrouvez<br/>nous sur : </h3>
              <div className="icons-socials">
                <a href="https://www.facebook.com/coyaliapp/" rel="noreferrer" target="_blanck">
                  <img src={facebook} alt=""/>
                </a>
                <a href="https://www.instagram.com/coyali_app/" target="_blanck">
                  <img src={insta} alt=""/>
                </a>
                <a href="https://fr.linkedin.com/company/coyali" target="_blanck">
                  <img src={linkdin} alt=""/>
                </a>
              </div>
            </div>

          </div>
          <div className="copywrite">
            <p>© Copyright 2023 - Coyali</p>
          </div>
        </footer>
      </div>
  );
}

export default GetUserInfos;
